<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-12 col-lg-6 layout-spacing">
          <form class="section general-info">
            <div class="info">
              <h6 class="">General Information</h6>
              <div class="row">
                <div class="col-lg-11 mx-auto">
                  <div class="row">
                    <div class="col-12">
                      <div class="form">
                        <div class="row">
                          <div class="col-12 text-center">
                            <img :src="user.image" class="w-25" alt="" />
                            <p class="mt-2">
                              <i class="flaticon-cloud-upload mr-1"></i> Upload
                              Picture
                            </p>
                            <b-form-file
                              v-model="file"
                              placeholder="Choose a file or drop it here..."
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                          </div>
                          <div class="col-sm-6 mt-4">
                            <div class="form-group">
                              <label for="firstName">First Name</label>
                              <input
                                type="text"
                                class="form-control mb-4"
                                id="firstName"
                                placeholder="First Name"
                                v-model="editUser.firstname"
                              />
                            </div>
                          </div>
                          <div class="col-sm-6 mt-4">
                            <div class="form-group">
                              <label for="lastName">Last Name</label>
                              <input
                                type="text"
                                class="form-control mb-4"
                                id="lastName"
                                placeholder="Last Name"
                                v-model="editUser.lastname"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group mt-2">
                          <label for="email">Email</label>
                          <input
                            type="email"
                            class="form-control"
                            name="email"
                            id="email"
                            placeholder="Email"
                            v-model="editUser.email"
                          />
                        </div>
                        <b-button
                          @click.prevent="updateInformation()"
                          :disabled="loadingInfo"
                        >
                          <b-spinner small v-if="loadingInfo"></b-spinner>
                          Save Changes
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="col-12 col-lg-6 layout-spacing">
          <!-- Update bank account -->
          <form
            class="section general-info mb-4"
            method="POST"
            @submit.prevent="updateBankAccount()"
            @keydown="bankAccountForm.onKeydown($event)"
          >
            <AlertError :form="bankAccountForm" />

            <div class="info">
              <h6 class="">Update Bank Account</h6>

              <div class="form-group">
                <label>Bank *</label>
                <v-select
                  v-model="bankAccountForm.bank_id"
                  :options="banks"
                  label="name"
                  :filterable="false"
                  @search="vselectSearch"
                  placeholder="Select a bank"
                  :reduce="(bank) => bank.id"
                >
                  <template #no-options> type to search banks... </template>

                  <template #list-header v-if="vselectSearchResults">
                    <div
                      class="bg-dark border-bottom p-3 d-flex justify-content-between"
                    >
                      <span class="small">
                        Total: {{ vselectSearchResults.total }}<br />
                        Pages: {{ vselectSearchResults.last_page }}<br />
                        Current Page: {{ vselectSearchResults.current_page }}
                      </span>
                      <div class="mb-2">
                        <b-btn
                          :disabled="!vselectSearchResults.prev_page_url"
                          @click="
                            vselectGoToPage(
                              vselectSearchResults.current_page - 1
                            )
                          "
                          variant="info"
                          class="mr-2"
                          size="sm"
                          squared
                        >
                          &laquo; Prev
                        </b-btn>
                        <b-btn
                          :disabled="!vselectSearchResults.next_page_url"
                          @click="
                            vselectGoToPage(
                              vselectSearchResults.current_page + 1
                            )
                          "
                          variant="success"
                          size="sm"
                          squared
                        >
                          Next &raquo;
                        </b-btn>
                      </div>
                    </div>
                  </template>
                </v-select>
              </div>

              <b-form-group
                label="Account Number *"
                label-for="account_number"
                :invalid-feedback="bankAccountForm.errors.get('account_number')"
                :state="!bankAccountForm.errors.has('account_number')"
              >
                <b-form-input
                  id="amount"
                  v-model="bankAccountForm.account_number"
                  placeholder="Enter your bank account number"
                  type="number"
                  :state="
                    bankAccountForm.errors.has('account_number') ? false : null
                  "
                  trim
                ></b-form-input>
              </b-form-group>

              <b-button @click="updateBankAccount()">
                <b-spinner
                  label="Loading"
                  v-if="bankAccountForm.busy"
                  small
                ></b-spinner>
                Update Bank Account
              </b-button>
            </div>
          </form>

          <!-- Update password -->
          <form class="section general-info">
            <div class="info">
              <h6 class="">Password</h6>
              <div class="row">
                <div class="col-lg-11 mx-auto">
                  <div class="row">
                    <div class="col-12">
                      <div class="form">
                        <div class="form-group mt-2">
                          <label for="currentpassword">Current Password</label>
                          <input
                            type="password"
                            class="form-control"
                            name="currentpassword"
                            id="currentpassword"
                            v-model="editPassword.oldPass"
                          />
                        </div>

                        <div class="form-group mt-2">
                          <label for="newpassword">New Password</label>
                          <input
                            type="password"
                            class="form-control"
                            name="newpassword"
                            id="newpassword"
                            v-model="editPassword.newPass"
                          />
                        </div>

                        <div class="form-group mt-2">
                          <label for="confirmnewpassword"
                            >Confirm New Password</label
                          >
                          <input
                            type="password"
                            class="form-control"
                            name="confirmnewpassword"
                            id="confirmnewpassword"
                            v-model="editPassword.newPass_confirmation"
                          />
                        </div>
                        <b-button
                          @click.prevent="updatePassword()"
                          :disabled="loadingPass"
                        >
                          <b-spinner small v-if="loadingPass"></b-spinner>
                          Save Changes
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import { editUser } from "@/models/user";
import vselectPaginationSearch from "@/mixins/v-select/pagination-search";

export default {
  name: "Profile",
  mixins: [vselectPaginationSearch],
  data() {
    return {
      loadingInfo: false,
      loadingPass: false,
      file: null,
      editUser: new editUser(),
      editPassword: {
        id: "",
        oldPass: "",
        newPass: "",
        newPass_confirmation: "",
      },
      bankAccountForm: new this.$Form({
        bank_id: "",
        account_number: "",
      }),
      banks: [],
    };
  },
  computed: {
    ...mapGetters(["url", "user", "gcurl", "michurl"]),
  },
  methods: {
    updateInformation() {
      if (
        this.editUser.email == "" ||
        this.editUser.firstname == "" ||
        this.editUser.lastname == ""
      ) {
        this.$toast.error("Missing input fields");
      } else {
        this.loadingInfo = true;

        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        let formData = new FormData();
        formData.append("id", this.editUser.id);
        formData.append("email", this.editUser.email);
        formData.append("firstname", this.editUser.firstname);
        formData.append("lastname", this.editUser.lastname);
        if (this.file != null) {
          formData.append("file", this.file);
        }

        http
          .post(endpoints.UPDATE_USER_INFORMATION, formData, config)
          .then((response) => {
            this.loadingInfo = false;
            this.$toast.success(response);

            this.fetchUserInfo();
            this.file = null;
          })
          .catch((error) => {
            this.loadingInfo = false;
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          });
      }
    },
    fetchUserInfo() {
      http
        .get(endpoints.GET_LOGGED_IN_USER)
        .then((response) => {
          this.$store.dispatch("updateInfo", response);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    updatePassword() {
      if (
        this.editPassword.oldPass == "" ||
        this.editPassword.newPass == "" ||
        this.editPassword.newPass_confirmation == ""
      ) {
        this.$toast.error("Missing input fields");
      } else {
        if (
          this.editPassword.newPass != this.editPassword.newPass_confirmation
        ) {
          this.$toast.error("Passwords do not match. Please try again.");
        } else {
          this.loadingPass = true;
          http
            .post(endpoints.UPDATE_USER_PASSWORD, {
              ...this.editPassword,
            })
            .then((response) => {
              this.loadingPass = false;
              this.$toast.success(response);
              this.editPassword.oldPass = "";
              this.editPassword.newPass = "";
              this.editPassword.newPass_confirmation = "";
            })
            .catch((error) => {
              this.loadingPass = false;
              this.$toast.error(
                error.response?.data?.message || error.response?.message
              );
            });
        }
      }
    },
    vselectComponentSearch(loading, vm) {
      http
        .get(endpoints.GET_ALL_BANKS, {
          params: {
            page: vm.vselectSearchPage,
            "filter[name]": vm.vselectSearchTerm,
          },
        })
        .then((response) => {
          vm.vselectSearchResults = response;
          vm.banks = response.data;
        })
        .catch((error) => {
          vm.$toast.error(error);
        })
        .finally(() => {
          loading(false);
        });
    },
    vselectComponentClearSelection() {
      this.bankAccountForm.clear();
    },
    fetchBankAccount() {
      http
        .get(endpoints.VIEW_BANK_ACCOUNT)
        .then((response) => {
          if (response.bank) {
            this.banks = [
              {
                id: response.bank.id,
                name: response.bank.name,
              },
            ];

            this.bankAccountForm.fill({
              bank_id: response.bank_id,
              account_number: response.account_number,
            });
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    updateBankAccount() {
      this.bankAccountForm
        .post(endpoints.UPDATE_BANK_ACCOUNT)
        .then((response) => {
          this.$toast.success(response);
          this.fetchBankAccount();
        })
        .catch((error) => {
          this.$toast.error(error.response?.message.message);
        });
    },
  },
  mounted() {
    this.editUser = this.user;
    this.editPassword.id = this.user.id;

    this.fetchBankAccount();
  },
};
</script>

<style scoped src="@/assets/css/users/account-setting.css"></style>
