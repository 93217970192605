import { EventBus } from "@/utils/event-bus";

export default {
  data() {
    return {
      vselectSearchTerm: '',

      vselectSearchResults: null,

      vselectSearchPage: 1,
    }
  },

  watch: {
    vselectSearchPage: function() {
      this.vselectPerformSearch(() => { return true; }, this);
    },
  },

  methods: {
    vselectSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.vselectSearchTerm = search;
        this.vselectPerformSearch(loading, this);
      }
    },

    vselectPerformSearch: _.debounce((loading, vm) => {
      vm.vselectComponentSearch(loading, vm);
    }, 350),

    vselectClearSelection() {
      this.vselectSearchTerm = '';
      this.vselectSearchResults = null;
      this.vselectSearchPage = 1;

      this?.vselectComponentClearSelection();
    },

    vselectGoToPage(page) {
      this.vselectSearchPage = page;
    },
  },

  created() {
    EventBus.$on('vselect-deselect', this.vselectClearSelection);
  },

  beforeDestroy() {
    this.vselectClearSelection();
  }
};
