export class newUser {
  constructor() {
    (this.firstname = ""),
      (this.lastname = ""),
      (this.email = ""),
      (this.role = "");
  }
}

export class editUser {
  constructor() {
    (this.id = ""),
      (this.firstname = ""),
      (this.lastname = ""),
      (this.email = ""),
      (this.role = "");
  }
}
